import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Results.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header/Header";
import SearchResultsComponent from "../Components/SearchResults";

declare global {
  interface Window {
    init?: (props: any) => void;
  }
}

type SearchResultData = {
  organic: {
    adType: string;
    title: string;
    description: string;
    siteHost: string;
    clickUrl: string;
  }[];
  sponsored: {
    adType: string;
    title: string;
    description: string;
    siteHost: string;
    favicon: string;
    clickUrl: string;
    partnerOptOut: boolean;
    reviewExtension: string;
    phrases: string[];
    beaconProps: {
      yiid: number;
      appns: string;
      k: number;
    };
  }[];
  products: {
    adType: string;
    id: string;
    storeName: string;
    brandName: string;
    title: string;
    imageUrl: string;
    description: string;
    originalPrice: number;
    dealPrice: number;
    currentPrice: number;
    currency: string;
    currencySymbol: string;
    isFreeShipping: boolean;
    clickUrl: string;
    beaconProps: {
      yiid: number;
      appns: string;
      k: number;
    };
  }[];
  related: string[];
};

interface Props {}

export const Results: React.FC<Props> = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryParam = queryParams.get("query") || "";
  const tidParam = queryParams.get("tid") || "";
  const clickidParam = queryParams.get("clickid") || "";

  const [results, setResults] = useState<SearchResultData>({
    organic: [],
    sponsored: [],
    products: [],
    related: [],
  });
  const [query] = useState(queryParam);
  const [nextToken, setNextToken] = useState<string | null>(null);

  const handleSearch = async () => {
    try {
      if (!query) return;

      const url = `https://api.fwazzy.com/feed?token=tHGBwl2V&organic=5&enableProducts=1&q=${query}&tid=${tidParam}&clickid=${clickidParam}`;

      const response = await fetch(url);
      if (response.ok) {
        const data = await response.json();
        console.log(data);

        // Add window.init(response.props) here
        if (window.init) {
          window.init(data.props);
        }

        setResults((prevResults) => {
          if (prevResults.organic.length === 0) {
            return {
              organic: [...data.organic],
              sponsored: [...data.sponsored],
              products: [...data.products],
              related: [...data.related],
            };
          }
          return {
            organic: [...prevResults.organic, ...data.organic],
            sponsored: [...prevResults.sponsored],
            products: [...prevResults.products],
            related: [...prevResults.related],
          };
        });
        setNextToken(
          data.props && data.props.nextPageToken
            ? data.props.nextPageToken
            : null
        );
      } else {
        console.error("Error fetching data");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }

    const navEvent = new Event("navigation");
    window.dispatchEvent(navEvent);
  };

  const setRelatedValues = async (search: any) => {
    window.location.href = `/results?query=${search}`;
    handleSearch();
  };

  useEffect(() => {
    if (query !== undefined) {
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      <div className="results-container">
        <SearchResultsComponent
          results={results}
          setRelatedValues={setRelatedValues}
          q={query}
        />

        {nextToken && (
          <button
            className="more-results-button"
            onClick={() => {
              handleSearch();
            }}
          >
            More Results...
          </button>
        )}
      </div>
      <Footer />
    </>
  );
};