import { FC, useEffect } from "react";
import Footer from "../Components/Footer";

import logoimg from "../img/fwazzy-high-resolution-logo-transparent.svg";
import logoimgb from "../img/fwazzy-icon.svg";
import compass from "../img/about-as-service-contact-information-concept.jpg";
import backgroundImage from '../img/white-modern-geometrical-wallpaper-with-hexagons.jpg';


const cardStyle = {
  padding: "20px",
  border: "1px solid #ccc",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  margin: "20px",
  marginTop: "120px",
  display: "flex",
};

const PagePrivacyPolicy: FC = () => {
  useEffect(() => {
    // Apply background image to the body
    document.body.style.backgroundImage = `url(${backgroundImage})`;
    document.body.style.height = "auto";
    // Cleanup the background image when the component is unmounted
    return () => {
      document.body.style.backgroundImage = "";
      document.body.style.backgroundSize = "";
    };
  }, []); // Empty dependency array ensures this effect runs only once, similar to componentDidMount

  return (
    <>
      <div style={cardStyle}>
        <div >
          <a href="/"><img
            src={logoimg}
            alt="ExploreBlitz Search Engine Illustration"
            style={{ maxWidth: '500px', marginBottom: '20px' }}
          /></a>

          <h2>About Fwazzy Search Engine</h2>

          <p style={{ width: "400px" }}>
            Welcome to Fwazzy, your portal to an interactive and resilient search journey! Our objective is to provide top-notch results sourced from all corners of the internet, ensuring users have access to the finest information out there.
          </p>

          <p style={{ width: "400px" }}>
            Crafted for rapidity and accuracy, Fwazzy Search stands as your reliable companion in effortlessly retrieving precise information. Whether you're in pursuit of news, articles, or anything else, our search platform is dedicated to simplifying your online quests for utmost efficiency and satisfaction.
          </p>

      
          <p style={{ width: "400px" }}>
            Embrace and unleash the full potential of our search engine. We highly value your insights and recommendations, so please don't hesitate to get in touch with us as we continually work towards enriching your search encounters.
          </p>
         
          <div>
            <a href="/">  <img
              src={logoimgb}
              alt="Our Team"
              style={{ maxWidth: '100px', marginTop: '50px' }}
            /> </a>
            <p>
              Thank you for choosing us!
            </p>
          </div>

        </div>

        <div>
          <img
            src={compass}
            alt="Our Team"
            style={{ maxWidth: '500px', marginTop: '180px' }}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PagePrivacyPolicy;