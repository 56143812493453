import { FC, useEffect } from "react";
import Footer from "../Components/Footer";

import logoimg from "../img/fwazzy-high-resolution-logo-transparent.svg";
import compass from "../img/flat-lay-telephone-receiver-with-chat-bubbles.jpg";
import backgroundImage from '../img/white-modern-geometrical-wallpaper-with-hexagons.jpg';


const cardStyle = {
  padding: "20px",
  border: "1px solid #ccc",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  margin: "20px",
  marginTop: "120px"
};

const PagePrivacyPolicy: FC = () => {
  useEffect(() => {
    // Apply background image to the body
    document.body.style.backgroundImage = `url(${backgroundImage})`;
    document.body.style.height = "auto";
    // Cleanup the background image when the component is unmounted
    return () => {
      document.body.style.backgroundImage = "";
      document.body.style.backgroundSize = "";
    };
  }, []); // Empty dependency array ensures this effect runs only once, similar to componentDidMount
  return (
    <>
      <div style={cardStyle}>

        <img
          src={logoimg}
          alt="Search Engine Illustration"
          style={{ maxWidth: '500px', marginBottom: '20px' }}
        />
        <h2>Contact Us</h2>
        <div className="aboutmepage">

        </div>
        <p>
          If you have any questions or inquiries, feel free to reach out to us. We are here to assist you!
        </p>

        <h3>Company Information</h3>
        <p>
          <strong>Company Name:</strong> Trilee LTD <br />
          <strong>Address:</strong> Address: Israel Hayarkon 5 Kfar Yona 4032137
        </p>

        <h3>Contact Details</h3>
        <p>
          <strong>Email:</strong> <a href="mailto:contact@trileehome.com">contact@trileehome.com</a><br />
          <strong>Phone:</strong>+972548078132<br />
          <strong>Website:</strong>
          <small>
            {" "}
            <a
              href="https://www.trileehome.com"
              target="_blank" rel="noreferrer"
            >
              https://www.trileehome.com
            </a>
          </small>
        </p>

        <p>
          We look forward to hearing from you!
        </p><br /><br />
        <div style={{ textAlign: "center" }}>
          <img
            src={compass}
            alt="Our Team"
            style={{ maxWidth: '500px', marginTop: '10px' }}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};
export default PagePrivacyPolicy;
