import * as React from "react";
import { Route, Routes } from "react-router-dom";

import { Home } from "./Pages/Home";
import { Results } from "./Pages/Results";
import PagePrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsOfUse from "./Pages/TermsOfUse";
import NA from "./Pages/About";
import NC from "./Pages/Contact";
const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/results" element={<Results />} />
      <Route path="/Privacy-Policy" element={<PagePrivacyPolicy />} />
      <Route path="/Terms-Of-Use" element={<TermsOfUse />} />
      <Route path="/about" element={<NA />} />
      <Route path="/contact" element={<NC />} />
    </Routes>
  );
};

export default App;
