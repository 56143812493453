import React from "react";
import "../App.css";

interface SearchResultProps {
  results: {
    organic: any[];
    sponsored: any[];
    products: any[];
    related: any[];
  };
  setRelatedValues: any;
  q: any;
}

const SearchResult: React.FC<SearchResultProps> = ({
  results,
  setRelatedValues,
  q
}) => {
  const parseHtmlEntities = (html: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    return doc.documentElement.textContent || "";
  };

  const handleRelatedClick = (
    event: React.MouseEvent,
    relatedString: string
  ) => {
    event.preventDefault();
    setRelatedValues(relatedString);
  };
  return (
    <div className="div-mob">



      <div className="related-container">
      {results.related.length > 0 && (<h5>Related Searches</h5>)}
        
        <ul
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          {results.related.map((relatedString, index) => (
            <li
              key={index}
              style={{
                border: "1px solid",
                margin: "5px",
                padding: "5px",
                borderRadius: "15px",
              }}
            >
              <button
                style={{ textDecoration: "none", color: "#333366" }}
                onClick={(event) => handleRelatedClick(event, relatedString)}
              >
                {relatedString}
              </button>
            </li>
          ))}
        </ul>
      </div>



      {(results.sponsored.length > 0 || results.products.length > 0) && (<h5>Ads Related to {q}</h5>)}
      {results.products.length > 0 && (
        <div className="product-results">
          <ul className="product-list">
            {results.products.map((item, index) => (
              <li key={index} className="mli">
                <div className="product">
                  <div
                    className="ad"
                    style={{
                      width: "200px",
                      height: "400px",
                      padding: "2.5px",
                      margin: "2.5px",
                      boxShadow: "3px 3px 6px rgba(0, 0, 0, 0.3)",
                    }}
                    data-yiid={item.beaconProps.yiid || ""}
                    data-appns={item.beaconProps.appns || ""}
                    data-k={item.beaconProps.k || ""}
                    onClick={() => window.open(item.clickUrl, "_blank")}
                  >
                    <img
                      src={item.imageUrl}
                      alt="Product"
                      style={{ width: "100%" }}
                    />
                    <div className="product-info ad-body">
                      <a
                        href={item.clickUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          display: 'block',
                          maxWidth: '100%',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        <span style={{ display: 'block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', lineHeight: '1.2em' }}>
                          {parseHtmlEntities(item.title)}
                        </span>
                      </a>
                      
                      <p className="deal-price">
                        <span style={{ textDecoration: "line-through" }}>
                          {item.currencySymbol + item.originalPrice}
                        </span>
                        <span style={{ fontWeight: "bold" }}>
                          {item.currencySymbol + item.currentPrice}
                        </span>
                      </p>
                      <p
                        className="description"
                        dangerouslySetInnerHTML={{
                          __html: parseHtmlEntities(item.description),
                        }}
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          // You can adjust these styles as needed to fit your design
                        }}
                      />
                      {item.isFreeShipping && (
                        <p
                          className="free-shipping"
                          style={{
                            color: "rgb(166, 195, 229)",
                            fontWeight: "bold",
                          }}
                        >
                          Free Shipping
                        </p>
                        
                      )}<p>{item.storeName}</p>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
      {results.products.length > 0 && <div style={{ width: '100%', height: '1px', backgroundColor: 'black', margin: '10px 0' }}></div>}
      {results.sponsored.length > 0 && (
        <div className="sponsored-results">
          <ul>
            {results.sponsored.map((item, index) => (
              <li key={index} className="mli">
                <div
                  className="sponsored-result"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div className="sponsored-image">
                    <img src={item.favicon} alt="Favicon" />
                  </div>
                  <div
                    className="sponsored-details"
                    data-yiid={item.beaconProps.yiid || ""}
                    data-appns={item.beaconProps.appns || ""}
                    data-k={item.beaconProps.k || ""}
                  >
                    <h1 style={{ marginTop: "-10px" }}>{item.siteHost}</h1>
                    <p>Sponsored</p>
                    <a
                      href={item.clickUrl}
                      target="_blank"
                      rel="noopener noreferrer" dangerouslySetInnerHTML={{
                        __html: parseHtmlEntities(item.title),
                      }}
                    >

                    </a>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: parseHtmlEntities(item.description),
                      }}
                    />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
      {results.sponsored.length > 0 && <div style={{ width: '100%', height: '1px', backgroundColor: 'black', margin: '10px 0' }}></div>}
      {results.organic.length > 0 && (
        <div className="organic-results">
          <ul>
            {results.organic.map((item, index) => (
              <li key={index} className="mli">
                <h1 style={{ marginTop: "10px" }}>{item.siteHost}</h1>
                <a
                  href={item.clickUrl}
                  target="_blank"
                  rel="noopener noreferrer" dangerouslySetInnerHTML={{
                    __html: parseHtmlEntities(item.title)
                  }}
                >

                </a>
                <p
                  dangerouslySetInnerHTML={{
                    __html: parseHtmlEntities(item.description),
                  }}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchResult;
